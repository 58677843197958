import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useAdosPage } from "../services/firestore";
import { Highlight } from '../components/Highlight'
import { Quotes } from '../components/Quote'

const AdosPage = ({ data }) => {
  const page = useAdosPage(data.allAdosPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Ados"}>
      {page && (
        <Highlight
          title={page.title}
          subtitle={page.subtitle}
          text={page.highlight}
        />
      )}

      {page && <Quotes quotes={page.quotes} />}
    </Layout>
  );
};

export const query = graphql`
  query {
    allAdosPages {
      nodes {
        title
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        image {
          image
          src
        }
        quotes {
          text
          author
        }
      }
    }
  }
`;

export default AdosPage;
