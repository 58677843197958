import React from "react";
import { Highlight } from './Highlight'

export const Quote = ({text, author}) => {
  return (
    <dl className="p-4 lg:p-16">
      <dt className="text-base italic">«{text}»</dt>
      <dt className="text-sm font-bold">- {author}</dt>
    </dl>
  )
}

export const Quotes = ({ quotes }) => {
  return (
    <Highlight isPrimary={true}>
      <ul className="lg:grid lg:grid-cols-3 text-left">
        {quotes.map(({ text, author }) => (
          <li key={text}>
            <Quote text={text} author={author} />
          </li>
        ))}
      </ul>
    </Highlight>
  )
}
